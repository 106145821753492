import { getFirestore } from "firebase/firestore";
import XLSX from "sheetjs-style";
import {
  query,
  getDocs,
  collectionGroup,
  where,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";

const db = getFirestore();

export class transcationService {
  static async getDatas(filterItems = false, afterAt = false) {
    const result = [];

    let q = collectionGroup(db, "transaction");

    if (filterItems.transactionType) {
      const transactionTypeArr = filterItems.transactionType.split("@");
      q = query(q, where("transactionType", "==", transactionTypeArr[1]));
    }

    if (filterItems.member_name) {
      q = query(q, where("member_name", "==", filterItems.member_name));
    }

    if (filterItems.member_id) {
      q = query(q, where("member_id", "==", filterItems.member_id));
    }

    if (filterItems.bonus) {
      q = query(q, where("bonus", "==", filterItems.bonus));
    }

    if (filterItems.store) {
      q = query(q, where("member_store", "==", filterItems.store));
    }

    if (filterItems.creator) {
      q = query(q, where("creator", "==", filterItems.creator));
    }

    if (filterItems.searchDateStart && filterItems.searchDateEnd) {
      const searchDateStart = new Date(filterItems.searchDateStart);
      const searchDateEnd = new Date(filterItems.searchDateEnd);
      searchDateEnd.setDate(searchDateEnd.getDate() + 1);

      q = query(
        q,
        where("entryTime", ">=", searchDateStart),
        where("entryTime", "<=", searchDateEnd)
      );
    } else {
      // 如果沒有搜尋時間區間的話，使用這個條件，因為之前有測試的資料
      q = query(q, where("entryTime", ">", new Date("2021/12/21 16:00:00")));
    }

    q = query(q, orderBy("entryTime", "desc"));

    if (afterAt) {
      q = query(q, startAfter(afterAt));
    }

    q = query(q, limit(20));

    const querySnapshot = await getDocs(q);

    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });

    return { datas: result, lastDoc: lastDoc };
  }

  static async exportDatas(filterItems = false) {
    try {
      const result = [];

      let q = collectionGroup(db, "transaction");

      if (filterItems.transactionType) {
        const transactionTypeArr = filterItems.transactionType.split("@");
        q = query(q, where("transactionType", "==", transactionTypeArr[1]));
      }

      if (filterItems.member_name) {
        q = query(q, where("member_name", "==", filterItems.member_name));
      }

      if (filterItems.member_id) {
        q = query(q, where("member_id", "==", filterItems.member_id));
      }

      if (filterItems.bonus) {
        q = query(q, where("bonus", "==", filterItems.bonus));
      }

      if (filterItems.creator) {
        q = query(q, where("creator", "==", filterItems.creator));
      }

      if (filterItems.store) {
        q = query(q, where("member_store", "==", filterItems.store));
      }

      if (filterItems.searchDateStart && filterItems.searchDateEnd) {
        const searchDateStart = new Date(filterItems.searchDateStart);
        const searchDateEnd = new Date(filterItems.searchDateEnd);
        searchDateEnd.setDate(searchDateEnd.getDate() + 1);

        q = query(
          q,
          where("entryTime", ">=", searchDateStart),
          where("entryTime", "<=", searchDateEnd)
        );
      } else {
        // 如果沒有搜尋時間區間的話，使用這個條件，因為之前有測試的資料
        q = query(q, where("entryTime", ">", new Date("2021/12/21 16:00:00")));
      }

      q = query(q, orderBy("entryTime", "desc"));

      const querySnapshot = await getDocs(q);

      // 將最後要輸出的json跑forEach調整的一下格式
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data["doType"] === "out") {
          data["bonus"] = "-" + data["bonus"];
        }

        data["entryTime"] = data["entryTime"].toDate().toLocaleString();

        result.push({
          會員帳號: data["member_id"],
          會員暱稱: data["member_name"],
          服務店家: data["member_store"],
          編號藝名: data["member_numberStageName"],
          交易時間: data["entryTime"],
          交易類型: data["transactionType"],
          G幣金額: data["bonus"],
          會員總金額: data["balance"],
          交易人員: data["creator"],
        });
      });

      const dataWS = XLSX.utils.json_to_sheet(result);

      // 調整每一個欄位的寬度
      dataWS["!cols"] = [
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 150,
        },
        {
          wpx: 150,
        },
        {
          wpx: 150,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
      ];

      for (let i = 1; i <= 9; i++) {
        const cellName = String(String.fromCharCode(i + 64) + "1");
        dataWS[cellName].s = {
          // set the style for target cell
          font: {
            name: "宋体",
            sz: 15,
            bold: true,
          },
          fill: {
            fgColor: { rgb: "f8f9fa" },
          },
        };
      }

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, dataWS, "G幣交易紀錄"); // sheetAName is name of Worksheet

      // export Excel file
      const now = new Date();
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      const h = now.getHours();
      const min = now.getMinutes();
      const s = now.getSeconds();

      // download xlsx
      const xlsx_file_name = "G幣交易紀錄_" + y + m + d + h + min + s + ".xlsx";
      XLSX.writeFile(wb, xlsx_file_name);
    } catch (e) {
      alert(e);
    }
  }
}
