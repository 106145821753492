<template>
  <h2>G幣交易紀錄</h2>

  <div class="mb-5">
    <a
      class="btn btn-secondary mb-3"
      data-bs-toggle="collapse"
      href="#collapseFilter"
      role="button"
      aria-expanded="false"
      aria-controls="collapseFilter"
    >
      查詢條件 <i class="bi bi-funnel"></i>
    </a>
    <div class="collapse" id="collapseFilter">
      <div class="card card-body">
        <form @submit.prevent="filter()">
          <div class="mb-3">
            <label class="form-label">會員帳號</label>
            <input
              type="text"
              class="form-control"
              v-model="filterItems.member_id"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">會員暱稱</label>
            <input
              type="text"
              class="form-control"
              v-model="filterItems.member_name"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">服務店家</label>
            <select class="form-select" v-model="filterItems.store">
              <option value="">--無--</option>
              <option v-for="(store, idx) in storeOptions" :key="idx">
                {{ store }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">交易類型</label>
            <select class="form-control" v-model="filterItems.transactionType">
              <option value="">--- 請選擇 ---</option>
              <optgroup label="存入">
                <option value="in@分潤G幣">分潤G幣</option>
                <option value="in@車資補貼">車資補貼</option>
                <option value="in@生日禮金">生日禮金</option>
                <option value="in@活動獎金">活動獎金</option>
                <option value="in@其他存入">其他存入</option>
              </optgroup>
              <optgroup label="提出">
                <option value="out@G幣兌現">G幣兌現</option>
                <option value="out@修正扣回">修正扣回</option>
                <option value="out@其他提取">其他提取</option>
              </optgroup>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">存提G幣金額</label>
            <input
              type="number"
              class="form-control"
              v-model="filterItems.bonus"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">時間</label>
            <div class="input-group mb-3">
              <span class="input-group-text">起始</span>
              <input
                type="date"
                class="form-control"
                v-model="filterItems.searchDateStart"
              />
            </div>
            <div class="input-group">
              <span class="input-group-text">結束</span>
              <input
                type="date"
                class="form-control"
                v-model="filterItems.searchDateEnd"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label">交易人員</label>
            <select class="form-control" v-model="filterItems.creator">
              <option value="" selected>--請選擇--</option>
              <option value="greentaco1113@gx888.com.tw">
                greentaco1113@gx888.com.tw
              </option>
              <option value="mimi20000112@hotmail.com">
                mimi20000112@hotmail.com
              </option>
              <option value="jonathan@gx888.com.tw">
                jonathan@gx888.com.tw
              </option>
              <option value="jie@gx888.com.tw">jie@gx888.com.tw</option>
              <option value="jean@gx888.com.tw">jean@gx888.com.tw</option>
              <option value="dong@gx888.com.tw">dong@gx888.com.tw</option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary">搜尋</button> &nbsp;
          <button type="button" class="btn btn-danger" @click="cancelSearch()">
            取消搜尋
          </button>
        </form>
      </div>
    </div>
  </div>

  <div class="text-end">
    <button class="btn btn-success mb-1" type="button" v-on:click="onexport">
      <i class="bi bi-file-earmark-spreadsheet"></i> Excel匯出
    </button>
    <div class="mb-3 text-primary">
      <i class="bi bi-exclamation-circle"></i>
      匯出建議：請不要在沒有做任何條件篩選時進行匯出，由於G幣交易紀錄可能資料量龐大，系統會根據匯出的資料量統計流量。
    </div>
  </div>

  <table class="table table-bordered table-hover">
    <thead class="sticky-top bg-light">
      <tr>
        <td width="50">序號</td>
        <td>會員帳號</td>
        <td>會員暱稱</td>
        <td>服務店家</td>
        <td>編號藝名</td>
        <td width="200">交易時間</td>
        <td width="150">交易類型</td>
        <td width="120">G幣金額</td>
        <td width="120">會員總金額</td>
        <td width="150">交易人員</td>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr v-for="(data, index) in datas" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <router-link
            target="_blank"
            :to="`/admin/member/${memberDetailPageType}/${data.memberDocId}`"
          >
            {{ data.member_id }}
          </router-link>
        </td>
        <td>{{ data.member_name }}</td>
        <td>{{ data.member_store }}</td>
        <td>{{ data.member_numberStageName }}</td>
        <td>{{ data.entryTime.toDate().toLocaleString() }}</td>
        <td :class="data.doType">{{ data.transactionType }}</td>
        <td :class="data.doType">
          <span v-if="data.doType === 'out'">-</span>{{ data.bonus }}
        </td>
        <td>{{ data.balance }}</td>
        <td>
          <small>{{ data.creator }}</small>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td>總計：{{ total_g }}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <div class="text-center">
    <button
      type="button"
      class="btn btn-secondary loadmore"
      v-if="lastDoc && datas.length >= 20"
      @click="loadMore()"
    >
      讀取更多資料
    </button>
  </div>

  <!-- <pre>{{ datas.length }}</pre> -->
</template>

<script>
import { transcationService } from "../../../services/transcationService.js";
import { settingService } from "../../../services/settingService.js";
export default {
  data() {
    return {
      datas: [],
      lastDoc: null,
      filterItems: {
        member_id: "",
        member_name: "",
        transactionType: "",
        bonus: "",
        searchDateStart: "",
        searchDateEnd: "",
        store: "",
      },
      memberDetailPageType: "",
      storeOptions: [],
    };
  },
  computed: {
    total_g: function () {
      let sum = 0;
      this.datas.forEach((e) => {
        if (e.doType == "in") {
          sum = sum + e.bonus;
        } else if (e.doType == "out") {
          sum = sum - e.bonus;
        }
      });

      return sum;
    },
  },
  methods: {
    async onexport() {
      if (
        confirm("確定要匯出？(為了避免資料量過大，建議進行條件篩選後再匯出)") ==
        true
      ) {
        await transcationService.exportDatas(this.filterItems);
      }
    },
    async getDatas() {
      const result = await transcationService.getDatas();
      this.datas = result.datas;
      this.lastDoc = result.lastDoc;
    },
    async filter() {
      if (
        (this.filterItems.searchDateStart && !this.filterItems.searchDateEnd) ||
        (!this.filterItems.searchDateStart && this.filterItems.searchDateEnd)
      ) {
        alert("請輸入完整的時間範圍");
      } else {
        const result = await transcationService.getDatas(this.filterItems);
        this.datas = result.datas;
        this.lastDoc = result.lastDoc;
      }
    },
    async loadMore() {
      const result = await transcationService.getDatas(
        this.filterItems,
        this.lastDoc
      );
      this.datas = this.datas.concat(result.datas);
      this.lastDoc = result.lastDoc;
    },
    async cancelSearch() {
      this.filterItems = {
        member_id: "",
        member_name: "",
        transactionType: "",
        bonus: "",
        searchDateStart: "",
        searchDateEnd: "",
      };

      const result = await transcationService.getDatas();
      this.datas = result.datas;
      this.lastDoc = result.lastDoc;
    },
  },
  async created() {
    this.getDatas();

    if (this.$store.state.adminUserRole === "memberAll_transcation") {
      this.memberDetailPageType = "view";
    } else {
      this.memberDetailPageType = "update";
    }

    // 服務店家清單
    this.storeOptions = await settingService.getStores();
  },
};
</script>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

td.in {
  color: #198754;
}

td.out {
  color: #dc3545;
}

.loadmore {
  margin-bottom: 15px;
}
</style>
